.App {
  background-color: #333231;
  margin: 20px auto;
  width: 320px;

  padding: 20px;
  border-radius: 10px;
  box-shadow: 6px 9px 20px 1px rgba(0, 0, 0, 0.68), inset -1px -6px 12px 0.1px #141414;
  position: relative;
  font-weight: 600;
}

.Display {
  margin: 0;
  padding: 5px 0 0 0;
  border: 5px inset #beb3b3;
}

.Buttons {
  height: 405px;
  padding-top: 25px;
  display: grid;
  column-gap: 10px;
  row-gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 1fr);
}