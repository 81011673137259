.Button {
  align-items: center;
  border-radius: 12.5%;
  font-size: x-large;
  cursor: pointer;
  /* height: 60px; */
  display: flex;
  justify-content: center;
  transition: filter 0.3s ease-in;
  background:  linear-gradient(to bottom, rgb(232, 230, 227) 100%, rgb(219, 218, 215) 0%);
  box-shadow: inset 0px -3px 3px 0px rgba(0, 0, 0, 0.75);
}

.Button:active,
.Button:focus {
  filter: brightness(120%);
}

.Button.function {
  color: #e0e0e7;
  background: linear-gradient(180deg,#4d3f3b 0,#504743);
}

.Button.operator {
  background: linear-gradient(180deg,#626262,0,#c4c2cc);
}

.Button.allclear {
  color: #e0e0e7;
  background: linear-gradient(180deg,#fe8a68 0,#cc5c33);
}

.Button:focus {
  outline: 0px;
}

.Button:active {
  opacity: 0.5;
}