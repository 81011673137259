.DisplaySpan {
  width: 100%;
  height: 20%;
}

.Led-display {
  background-color: #000000;
  display: block;
  box-sizing: border-box;
  margin: 0;
  padding: 2px;
}

.Led {
  color: white;
  width: 10%;
  font-size: 6vmin;
  border-color: black;
  display: inline-flex;
}